import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Taurus.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Taurus Health
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/taurus"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Taurus</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Taurus Health</h4>
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Taurus Health </h1>
                <h2 className="text-sm md:text-base ml-4">Apr 20 - May 20</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-love");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-nature");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-man");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-traits");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-facts");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                <p className="playfair text-black text-xl md:text-3xl">Taurus Health</p><br/>
                
            You are superbly healthy and robust. You possess an incredible physique and strong stamina from birth. So they are unlikely to suffer from significant health problems. But once you have suffered from illnesses, you may not have a quick recovery. You disbelieve the doctors and have a stubborn refusal to obey them. The primary basis for your conditions is the lack of fresh air and exercise. The health situations get more destructive as you grow older. You need to curb your dissipation and urge to eat and drink to have a wholesome life. Your constitution will be more robust if you do moderate workouts religiously.
 <br/><br/>
 This Know Everything report can be purchased for you or your loved one to understand life in depth.
  <br/><br/>
 <p className="font-bold inline-block text-orange-500">Read 2024 Horoscope:</p><br/>
 Taurus Health And Fitness Horoscope for 2020
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Possible health concerns</p><br/>
 The sensitive areas for diseases and infection are the neck, throat, ears, lower jawline, legs, ankles, reproductive organs, the back, and the spinal area. You have a sensitive throat, which may occasionally lead to congestion when suffering from a cold. It would help if you were careful of throat infections, goiter, and breathing disorders such as asthma. 
 
 
 <br/><br/>
 You tend to fill in food and drink and may put on weight which can make you tardy. The overweight can strain your heart and scourges you with poor circulation, weak ankles, varicose veins, and other chronic complaints. Gout can be another chance of significant infections. You have the risk of genitals, womb, liver, kidneys, abscesses, and rheumatism diseases. Your digestion and metabolism are slow.
 
 <br/><br/>
 Your vulnerable body parts include the upper torso, throat, teeth, chin, palate, neck, larynx, ears, and jaw. You will often complain about croup, tonsils, stiff neck, cervical pain, sore throat, swollen glands, laryngitis, injuries around the neck, and earaches.
 <br/><br/>
 
 <p className="font-bold inline-block text-orange-500">Also read:</p><br/>
 Taurus Yearly Health And Wellbeing Horoscope
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Food for Taurus</p>
 <br/><br/>
 As you grow old, you are prone to sluggishness, heaviness, and slow metabolism. You have a deep fondness for food from fried peppers to chocolate whipped cream cake and pickles. You eat without checking/tracing indigestion. Many of you get frustrated because of your heavyweight, which causes many problems throughout your life. You should adopt a low-fat, sugar, and starch diet. You should contain natural iodine and a pinch of iodized salt in your daily diet to function your thyroid gland optimally. It would be best if you consisted of salads and fresh fruits in your diet.
 <br/><br/>
 
 You should consume food with enough sodium sulfate to control the amount of water in the system. There are many to affect the thyroid and bloating but the main cause of this disease due to imbalance of this mineral. You should set a limitation for consuming rich food items. Add green vegetables to your diet and add dried blueberries and cranberries, vegetables like beetroot, nuts, pumpkin, peas, cauliflower, spinach, cucumbers, onions, peas, and almonds to your diet as they are rich in protein, vitamins, minerals, and sodium sulfate. 
 
 <br/><br/>
 People who avoid chocolate entirely improve their health, but that's not true; you have to consume these items just for your taste in less quantity and not consume them in excess, which may cause your health. So, do not give the chocolate and goodies instantly; otherwise, you'll feel deprived; eat less and sensibly. It is best to drink many glasses of water to prevent dehydration or water scarcity.
 
 <br/><br/>
 
 <p className="font-bold inline-block text-orange-500">Also read:</p><br/>
 Taurus Daily Health and Wellbeing Horoscope
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Taurus Physical Appearance</p><br/>
 The native possesses an intangible. They are one of the most attractive personalities. The females have calm and limpid eyes. She will step out and maintain herself gracefully, indolently but with the proposal of secret robustness. 
 <br/><br/>
 The associates will often have broad and muscular necks. Your shoulders, chest, torso, and back will be extensive and muscular. You look powerful and demanding even if you are overweight. The lean Taureans also appear physically dense and stout. You will have a well-proportioned, thick, quadrangular body irrespective of your height. You are generally fit and of medium to tall size. They typically have small ears and are close to the head. 
 <br/><br/>
 You munch very slowly while eating and have an excellent digestive system. You have strong and thick limbs. Most of you have wavy and curly hair, dark eyes, and dusky skin tone. Your round and pleasant face with sensual lips and appealing eyes will easily attract others. It bothers me when you don't show the fragility of mind or character.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Taurus beauty</p><br/>
 The natives usually have smooth skin and beautiful and defined eyes. You look most promising in blue and pink colors. Your gesture has a flower-patterned touch, so floral scarves and bold earrings look exemplary on you. You decorate your rosebud lips with radiant lipstick and apply a bright nail paint color. You are luscious and look great in costumes of pastel shades like light violet, pink and yellow. You choose soothing material and delicate jewelry. 
 <br/><br/>
 
 You opt to wear a dress and supplements that are evergreen and elegant. You do well in more good things like cream and lotions, making you look more graceful. You take care of how you greet yourself while choosing clothes/dresses and take a lot of time to decide. You walk gracefully and carry outfits pleasingly. You spend the most significant money on shopping for garments and accessories. You don't drive behind the style and wear those things you are comfortable with.
  <br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
